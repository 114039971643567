<template>
  <section class="plate">
    <h1 class="plate-title">合同分析</h1>
    <!-- 合同数 -->
    <div class="quantity">
      <div class="quantity-left green" @click="linkTo(6)">
        <p class="van-hairline--bottom">
          <span class="font-12">新签合同</span>
          <span class="font-14  bold">{{ cntractCount.newSignContractCount || "--" }}<van-icon name="arrow" /></span>
        </p>
        <p>
          <span class="font-12">面积</span>
          <span class="font-14  bold">{{ cntractCount.newSignResourceArea || "--" }}</span>
        </p>
      </div>
      <div class="quantity-right blue" @click="linkTo(7)">
        <p class="van-hairline--bottom">
          <span class="font-12">续签合同</span>
          <span class="font-14  bold">{{ cntractCount.renewContractCount || "--" }}<van-icon name="arrow" /></span>
        </p>
        <p>
          <span class="font-12">面积</span>
          <span class="font-14 bold">{{ cntractCount.renewResourceArea || "--" }}</span>
        </p>
      </div>
    </div>
    <!-- 一个月到期合同 -->
    <div class="expire">
      <div class="expite-box">
        <p class="font-14 box-top van-hairline--bottom"><van-icon name="credit-pay" class="box-icon" />一个月到期</p>
        <div class="box-bottom">
          <p class="van-hairline--right"><span class="font-12">合同数</span><span class="font-14 bold">{{
            contractExp && contractExp[0].contractNum || "--"
          }}</span></p>
          <p><span class="font-12">面积（㎡）</span><span class="font-14 bold">{{
            contractExp && contractExp[0].contractArea || "--"
          }}</span></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getContractCount, getContractExp } from "@/api/home/home.js";
import { getLastDay } from '@/utils/utils';
export default {
  data() {
    return {
      paramsa: {
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        toDate: new Date(getLastDay()).getTime(),
      },
      paramsb: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        endDate: new Date(getLastDay()).getTime(),
      },
      cntractCount: "",
      contractExp: "",
    }
  },
  mounted() {
    this.getContractCount();
    this.getContractExp();
  },
  methods: {
    // 跳转
    linkTo(n) {
      this.$router.push('/contract/homeContractList?popoutId=' + n);
    },

    // 【请求】获取合同数量
    getContractCount() {
      let data = this.paramsa;
      getContractCount(data).then((res) => {
        if (res.success) {
          this.cntractCount = res.data;
        }
      });
    },
    // 【请求】获取到期数量
    getContractExp() {
      let data = this.paramsb;
      getContractExp(data).then((res) => {
        if (res.success) {
          this.contractExp = res.data;
        }
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.plate {
  background: #fff;
  margin: .15rem 0;
  border-radius: .06rem;
}

.plate-title {
  font-size: .14rem;
  font-weight: bold;
  color: #333333;
  padding: .15rem;
}

.quantity {
  display: flex;
  justify-content: space-between;
  padding: 0 .15rem .15rem .15rem;
}

.expire {
  padding: 0 .15rem .15rem .15rem;
}

.expite-box {
  background: rgba(248, 248, 248, 1);
  border-radius: .06rem;
}

.box-top {
  padding: .15rem;
}

.box-bottom {
  display: flex;
  justify-content: space-between;

  >p {
    padding: .15rem;
    width: calc(50% - 5px);
    display: flex;
    justify-content: space-between;
  }
}


.box-icon {
  font-size: .16rem;
  margin-right: .1rem;
}


.quantity-left {
  border-radius: .06rem;
  background: rgba(87, 185, 136, 0.06);
  width: calc(50% - 5px);

  >p {
    display: flex;
    justify-content: space-between;
    padding: .1rem;
  }
}

.quantity-right {
  border-radius: .06rem;
  background: rgba(79, 148, 243, 0.06);
  width: calc(50% - 5px);

  >p {
    display: flex;
    justify-content: space-between;
    padding: .1rem;
  }
}


// 公共样式
.bold {
  font-weight: bold;
}

.grey {
  color: #666666;
}

.black {
  color: #333333;
}

.blue {
  color: #4F94F3;
}

.green {
  color: #57B988;
}

.font-12 {
  font-size: .12rem;
}

.font-14 {
  font-size: .14rem;
}
</style>
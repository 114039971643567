import Request from '@/utils/request';

// 变更所选园区
export function getChangeProject(data) {
  return Request('post', '/apg/common/loginChange/changeProject', data)
}

// 房源数据
export function getResourceCount(data) {
  return Request('post', '/apg/workBench/indexData/indexManage', data)
}

// 房源列表
export function getRoomList(data) {
  return Request('post', '/apg/workBench/newIndex/indexResourceManage', data)
}

// 运营概览
export function getBaseAvgPrice(data) {
  return Request('post', '/apg/workBench/newIndex/coreIndex', data)
}

// 运营列表
export function getOperationList(data) {
  return Request('post', '/apg/workBench/newIndex/operationList', data)
}

// 合同数量
export function getContractCount(data) {
  return Request('post', '/apg/workBench/indexData/collectionContract', data)
}

// 到期数量
export function getContractExp(data) {
  return Request('post', '/apg/workBench/indexData/futureContractExp', data)
}

// 账单数量
export function getBillCount(data) {
  return Request('post', '/apg/workBench/indexData/collectionInfo', data)
}


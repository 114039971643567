<template>
  <section class="plate">
    <h1 class="plate-title">房源数据</h1>
    <!-- 房源 -->
    <div class="resource">
      <div class="resource-left" @click="onRoomList">
        <div class="left-top">
          <p class="grey font-12">房间数量<van-icon name="arrow" /></p>
          <p class="black font-14 bold">{{ dataInfo.resourceTotal || "--" }}</p>
        </div>
        <div class="left-mid"></div>
        <div class="left-bottom">
          <p class="green font-12">面积（㎡）<van-icon name="arrow" /></p>
          <p class="green font-14 bold">{{ dataInfo.resourceArea || "--" }}</p>
        </div>
      </div>
      <div class="resource-right">
        <div class="right-top" @click="onRoomList">
          <p class="grey font-12">在租房源丨面积（㎡）<van-icon name="arrow" /></p>
          <p class="black font-14">
            <span class="bold">{{ dataInfo.rentedTotal || "--" }}</span>丨<span class="bold">{{
              dataInfo.rentedArea ||
              "--"
            }}</span>
          </p>
        </div>
        <div class="right-mid"></div>
        <div class="right-bottom" @click="linkTo('/resource/resourceVacantList')">
          <p class="blue font-12">空置房源丨面积（㎡）<van-icon name="arrow" /></p>
          <p class="black font-14">
            <span class="bold">{{ dataInfo.releasedTotal || "--" }}</span>丨<span class="bold">{{
              dataInfo.releasedArea ||
              "--"
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 房源列表 -->
    <RoomList ref="RoomList"></RoomList>
  </section>
</template>

<script>
import { getResourceCount } from "@/api/home/home.js";
import RoomList from "@/views/home/table/RoomList";
export default {
  components: { RoomList },
  data() {
    return {
      paramsa: {
        endDate: new Date().getTime(),
        startDate: new Date(new Date().getFullYear(), 0, 1).getTime(),
      },
      dataInfo: "",
    }
  },
  mounted() {
    this.getResourceCount();
  },
  methods: {
    // 跳转
    linkTo(path) {
      this.$router.push(path);
    },

    // 【请求】获取房源数量
    getResourceCount() {
      let data = this.paramsa;
      getResourceCount(data).then((res) => {
        if (res.success) {
          this.dataInfo = res.data;
        }
      });
    },

    // 【监听】房源列表
    onRoomList() {
      this.$refs.RoomList.onShow();
    }
  }
};
</script>


<style lang="scss" scoped>
.plate {
  background: #fff;
  margin: .15rem 0;
  border-radius: .06rem;
}

.plate-title {
  font-size: .14rem;
  font-weight: bold;
  color: #333333;
  padding: .15rem;
}

// 房源
.resource {
  display: flex;
  justify-content: space-between;
  padding: 0 .15rem .15rem .15rem;
}

.resource-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(40% - 5px);
  background: url("~@/assets/images/home/info/home.png");
  background-size: 100% 100%;
}

.resource-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(60% - 5px);
}

.left-top,
.left-bottom {
  padding: .15rem;
}

.right-top,
.right-bottom {
  padding: .15rem;
}

.right-mid,
.left-mid {
  height: .15rem;
}

.right-top {
  background: url("~@/assets/images/home/info/zz.png");
  background-size: 100% 100%;
}

.right-bottom {
  background: url("~@/assets/images/home/info/kz.png");
  background-size: 100% 100%;
}

.tofu {
  padding: .15rem;
  display: flex;
  flex-wrap: wrap;
}

.tofu-item {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;

  >p {
    padding: .05rem 0;
  }
}

// 公共样式
.bold {
  font-weight: bold;
}

.grey {
  color: #666666;
}

.black {
  color: #333333;
}

.blue {
  color: #4F94F3;
}

.green {
  color: #57B988;
}

.font-12 {
  font-size: .12rem;
}

.font-14 {
  font-size: .14rem;
}
</style>
<template>
  <div id="footer">
    <van-tabbar v-model="active" route>
      <van-tabbar-item
        v-for="item in footerList"
        :name="item.name"
        :key="item.name"
        :to="item.name"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: "/home",
      footerList: [
        {
          name: "/home/homeInfo",
          title: "首页",
          icon: {
            active: require("../assets/images/home/footer/home_active.png"),
            inactive: require("../assets/images/home/footer/home.png"),
          },
        },
        {
          name: "/task/taskList",
          title: "审批台账",
          icon: {
            active: require("../assets/images/home/footer/task_active.png"),
            inactive: require("../assets/images/home/footer/task.png"),
          },
        },
        {
          name: "/clue/clueList",
          title: "招商线索",
          icon: {
            active: require("../assets/images/home/footer/clue_active.png"),
            inactive: require("../assets/images/home/footer/clue.png"),
          },
        },
        {
          name: "/myself/myselfInfo",
          title: "我的",
          icon: {
            active: require("../assets/images/home/footer/myself_active.png"),
            inactive: require("../assets/images/home/footer/myself.png"),
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#footer {
  .van-tabbar--fixed {
    position: relative;
  }
}
</style>
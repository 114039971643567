<template>
  <div id="home">
    <Header></Header>
    <main>
      <!-- 菜单 -->
      <section class="menu">
        <van-row>
          <van-col span="6" class="menu-li" v-for="item in menuList" :key="item.title" @click="linkTo(item.path)">
            <img :src="item.imgSrc" alt="" />
            <span>{{ item.title }}</span>
          </van-col>
        </van-row>
      </section>
      <!-- 房源数据 -->
      <RoomAnalyse></RoomAnalyse>
      <!-- 运营概览 -->
      <PropertyAnalyse></PropertyAnalyse>
      <!-- 合同分析 -->
      <ContractAnalyse></ContractAnalyse>
      <!-- 财务收款分析 -->
      <BillAnalyse></BillAnalyse>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Header from "@/components/header";
import { menuList } from "@/db/home";
import RoomAnalyse from "./child/RoomAnalyse"
import PropertyAnalyse from "./child/PropertyAnalyse"
import ContractAnalyse from './child/ContractAnalyse'
import BillAnalyse from './child/BillAnalyse'
export default {
  components: { Footer, Header, RoomAnalyse, PropertyAnalyse, ContractAnalyse, BillAnalyse },
  data() {
    return {
      menuList,
      porgectList: "",
    };
  },
  methods: {
    // 【监听】菜单点击跳转
    linkTo(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
  overflow: auto;
  padding: 0.1rem;
  background: #F1F2F6;
}

.menu {
  display: flex;
  flex-direction: column;
}

.menu-p {
  font-weight: bold;
  margin: 0.2rem 0;
}

.menu-li {
  display: flex;
  flex-direction: column;
  align-items: center;

  >img {
    display: block;
    width: 0.48rem;
    height: 0.48rem;
    margin-bottom: 0.1rem;
  }

  >span {
    font-size: 0.13rem;
  }
}
</style>
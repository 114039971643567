<template>
  <section class="plate">
    <h1 class="plate-title">财务收款分析</h1>
    <div class="plate-box">
      <div class="box-item">
        <div class="item" @click="linkTo(1)">
          <div class="count">
            <p class="item-p"><span class="grey font-12">应收合同数</span><span class="black font-14 bold">{{
              billCount.billCnt || "--"
            }}<van-icon name="arrow" /></span></p>
            <van-progress :percentage="100" :show-pivot="false" color="#57B988" />
          </div>
          <p class="item-pp"><span class="grey font-12">金额</span><span class="black font-14 bold">{{
            billCount.billArmount || "--"
          }}</span></p>
        </div>
        <div class="item" @click="linkTo(3)">
          <div class="count">
            <p class="item-p"><span class="grey font-12">未收合同数</span><span class="black font-14 bold">{{
              billCount.billUnPaidCnt || "--"
            }}<van-icon name="arrow" /></span></p>
            <van-progress :percentage="calcPercent(billCount.billUnPaidCnt, billCount.billCnt)" :show-pivot="false"
              color="#4F94F3" />
          </div>
          <p class="item-pp"><span class="grey font-12">金额</span><span class="black font-14 bold">{{
            billCount.billUnPaidAmount || "--"
          }}</span></p>
        </div>
      </div>
      <div class="box-item">
        <div class="item" @click="linkTo(4)">
          <div class="count">
            <p class="item-p"><span class="grey font-12">申请延期合同数</span><span class="black font-14 bold">{{
              billCount.billDelayCnt || "--"
            }}<van-icon name="arrow" /></span></p>
            <van-progress :percentage="calcPercent(billCount.billDelayCnt, billCount.billCnt)" :show-pivot="false"
              color="#FFB304" />
          </div>
          <p class="item-pp"><span class="grey font-12">金额</span><span class="black font-14 bold">{{
            billCount.billDelayAmount || "--"
          }}</span></p>
        </div>
        <div class="item" @click="linkTo(5)">
          <div class="count">
            <p class="item-p"><span class="grey font-12">逾期合同数</span><span class="black font-14 bold">{{
              billCount.billOverdueCnt || "--"
            }}<van-icon name="arrow" /></span></p>
            <van-progress :percentage="calcPercent(billCount.billOverdueCnt, billCount.billCnt)" :show-pivot="false"
              color="#FF5C5C" />
          </div>
          <p class="item-pp"><span class="grey font-12">金额</span><span class="black font-14 bold">{{
            billCount.billOverdueAmount || "--"
          }}</span></p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { getBillCount } from "@/api/home/home.js";
import { getLastDay } from '@/utils/utils';
export default {
  data() {
    return {
      params: {
        selectDateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
        selectDateTo: new Date(getLastDay()).getTime(),
      },
      billCount: "",
    }
  },
  mounted() {
    this.getBillCount();
    console.log(new Date(getLastDay()).getTime());
  },
  methods: {
    // 跳转
    linkTo(n) {
      this.$router.push('/contract/homeBillList?popoutId=' + n);
    },

    calcPercent(count, total) {
      if (count && total) {
        return (count / total).toFixed(0) * 100
      } else {
        return 0
      }
    },

    // 【请求】获取房源数量
    getBillCount() {
      let data = this.params;
      getBillCount(data).then((res) => {
        if (res.success) {
          this.billCount = res.data;
        }
      });
    },
  }
};
</script>


<style lang="scss" scoped>
.plate {
  background: #fff;
  margin: .15rem 0;
  border-radius: .06rem;
}

.plate-title {
  font-size: .14rem;
  font-weight: bold;
  color: #333333;
  padding: .15rem;
}

.plate-box {
  padding: 0 .15rem .15rem .15rem;
}

.box-item {
  display: flex;
  justify-content: space-between;
}

.count {
  background: #fff;
  border-radius: .06rem;
  padding: .1rem .1rem .15rem .1rem;
  box-shadow: 1px 2px 4px rgba(0, 21, 41, 0.16);
}

.item {
  width: calc(50% - 5px);
  background: rgba(248, 248, 248, 1);
  border-radius: .06rem;
  margin-bottom: .15rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-p {
  display: flex;
  justify-content: space-between;
  padding: .1rem 0;
}

.item-pp {
  display: flex;
  justify-content: space-between;
  padding: .1rem;
}

// 公共样式
.bold {
  font-weight: bold;
}

.grey {
  color: #666666;
}

.black {
  color: #333333;
}

.blue {
  color: #4F94F3;
}

.green {
  color: #57B988;
}

.font-12 {
  font-size: .12rem;
}

.font-14 {
  font-size: .14rem;
}
</style>
<template>
  <van-popup class="page" v-model:show="showFlag" position="right">
    <van-nav-bar title="房源列表" left-text="返回" left-arrow @click-left="onClickLeft" />
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <!-- 文本展示区 -->
        <section class="block-item" v-for="item in tableData" :key="item.projectId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.projectName }}</div>
            <div class="title-right">
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">园区成熟标识：</span>
              <span>{{ operationStatusObj[item.operationStatus] || "-" }}</span>
            </p>
            <p>
              <span class="info-color">基准均价：</span>
              <span>{{ item.baseAvgPrice || "-" }}</span>
            </p>
            <p>
              <span class="info-color">在租均价：</span>
              <span>{{ item.rentedAvgPrice || "-" }}</span>
            </p>
            <p>
              <span class="info-color">在租实价：</span>
              <span>{{ item.nowRentPrice || "-" }}</span>
            </p>
            <p>
              <span class="info-color">在租客户数/户均面积：</span>
              <span>{{ item.tenantCount || "-" }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{{ item.tenantAvgArea || "-" }}</span>
            </p>
            <p>
              <span class="info-color">预定客户数/面积：</span>
              <span>{{ item.preTenantCount || "-" }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{{ item.preTenantArea || "-" }}</span>
            </p>
            <!-- 全部 -->
            <template v-if="tableParams.queryType == ''">
              <p>
                <span class="info-color">年计租率：</span>
                <span>{{ item.yearRentRate + "%" || "-" }}</span>
              </p>
              <p>
                <span class="info-color">年出租率：</span>
                <span>{{ item.yearOutRate + "%" || "-" }}</span>
              </p>
            </template>
            <!-- 招商期 -->
            <template v-if="tableParams.queryType == 1">
              <p>
                <span class="info-color">招商期年计租率：</span>
                <span>{{ item.noMatureRentRate + "%" || "-" }}</span>
              </p>
              <p>
                <span class="info-color">招商期出租率：</span>
                <span>{{ item.attractRentalRate + "%" || "-" }}</span>
              </p>
            </template>
            <!-- 运营期 -->
            <template v-if="tableParams.queryType == 2">
              <p>
                <span class="info-color">运营期年计租率：</span>
                <span>{{ item.matureRentRate + "%" || "-" }}</span>
              </p>
              <p>
                <span class="info-color">运营期出租率：</span>
                <span>{{ item.operateRentalRate + "%" || "-" }}</span>
              </p>
            </template>
            <p>
              <span class="info-color">本月新签均价：</span>
              <span>{{ item.monthSignPrice || "-" }}</span>
            </p>
            <p>
              <span class="info-color">本年新签均价：</span>
              <span>{{ item.yearSignPrice || "-" }}</span>
            </p>
          </div>
        </section>
        <!-- 文本展示区end -->
      </van-list>
    </main>
  </van-popup>
</template>

<script>
import { getOperationList } from "@/api/home/home.js";
import { operationStatusObj } from "@/db/home.js";
export default {
  data() {
    return {
      operationStatusObj,
      showFlag: false,
      // table表格
      tableData: [],
      loading: false,
      finished: false,
      tableParams: {
        page: 1,
        limit: 10,
        queryType: ""
      },
    }
  },
  methods: {
    // 【请求】获取表格数据
    getOperationList() {
      let data = this.tableParams;
      getOperationList(data).then((res) => {
        if (res.success && res.data) {
          this.tableData = [...this.tableData, ...res.data];
          this.loading = false;
          if (this.tableData.length >= res.count) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 【请求】下拉加载
    onLoad() {
      this.tableParams.page++;
      this.getOperationList();
    },

    // 【监听】筛选重载
    reset() {
      this.tableData = [];
      this.finished = false;
      this.tableParams.page = 1;
      this.getOperationList();
    },

    // 【监听】打开
    onShow(queryType) {
      this.tableParams.queryType = queryType;
      this.showFlag = true;
      this.reset();
    },

    // 【监听】返回
    onClickLeft() {
      this.showFlag = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.van-nav-bar {
  background: #1989fa;
}

main {
  flex: 1;
  overflow: auto;
}

// 文本展示

.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  background: #fff;

  >.block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;

    .title-left {
      display: flex;
      align-items: center;

      >i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }

  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;

    >p {
      display: flex;
      align-items: center;
      padding: 0.02rem 0;
      font-size: .14rem;
    }

    .info-color {
      display: flex;
      align-items: center;

      .van-image {
        margin-right: 0.05rem;
      }
    }
  }
}
</style>

<style lang="scss">
.page {

  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff;
  }
}
</style>
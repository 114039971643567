<template>
  <section class="plate">
    <h1 class="plate-title">运营概览</h1>
    <ul class="ul">
      <li class="li" v-for="(item, index) in infoList" :key="index" @click="onOperationList(item)">
        <div class="li-box">
          <div class="li-item">
            <span>{{ item.title }}<van-icon name="arrow" /></span>
            <b class="fn-14">
              {{ item.count }}
              <i v-if="item.line">/</i>
              {{ item.area }}
            </b>
          </div>
        </div>
      </li>
    </ul>
    <!-- 房源列表 -->
    <OperationList ref="OperationList"></OperationList>
  </section>
</template>

<script>
import { getBaseAvgPrice } from "@/api/home/home.js";
import OperationList from "@/views/home/table/OperationList";
export default {
  components: { OperationList },
  data() {
    return {
      infoList: [
        // 指标
        {
          title: "基准均价",
          count: "-",
          codeName: "baseAvgPrice",
          line: false,
          queryType: "",
        },
        {
          title: "在租均价/在租实价",
          count: "-",
          codeName: "rentedAvgPrice",
          area: "-",
          areaName: "nowRentPrice",
          line: true,
          queryType: "",
        },
        {
          title: "在租客户数/户均面积",
          count: "-",
          codeName: "tenantCount",
          area: "-",
          areaName: "tenantAvgArea",
          line: true,
          queryType: "",
        },
        {
          title: "预定客户数/面积",
          count: "-",
          codeName: "preTenantCount",
          area: "-",
          areaName: "preTenantArea",
          line: true,
          queryType: "",
        },
        {
          title: "招商期年计租率(%)",
          count: "-",
          codeName: "noMatureRentRate",
          line: false,
          queryType: "1",
        },
        {
          title: "运营期年计租率(%)",
          count: "-",
          codeName: "matureRentRate",
          line: false,
          queryType: "2",
        },
        {
          title: "招商期出租率(%)",
          count: "-",
          codeName: "attractRentalRate",
          line: false,
          queryType: "1",
        },
        {
          title: "运营期出租率(%)",
          count: "-",
          codeName: "operateRentalRate",
          line: false,
          queryType: "2",
        },
        {
          title: "本月新签均价",
          count: "-",
          codeName: "monthSignPrice",
          line: false,
          queryType: "",
        },
        {
          title: "本年新签均价",
          count: "-",
          codeName: "yearSignPrice",
          line: false,
          queryType: "",
        },
      ],
      deadline: "",
    }
  },
  mounted() {
    this.getBaseAvgPrice();
  },
  methods: {
    // 跳转
    linkTo(path) {
      this.$router.push(path);
    },

    // 【请求】获取房源基准均价
    getBaseAvgPrice() {
      let data = {};
      getBaseAvgPrice(data).then((res) => {
        if (res.success) {
          if (res.data) {
            this.deadline = res.data.deadline;
            for (let i of this.infoList) {
              i.count = res.data[i.codeName] || "-";
              if (i.areaName) {
                i.area = res.data[i.areaName] || "-"
              }
            };
          }
        }
      });
    },

    // 【监听】运营列表
    onOperationList(item) {
      this.$refs.OperationList.onShow(item.queryType);
    }
  }
};
</script>


<style lang="scss" scoped>
.plate {
  background: #fff;
  margin: .15rem 0;
  border-radius: .06rem;
}

.plate-title {
  font-size: .14rem;
  font-weight: bold;
  color: #333333;
  padding: .15rem;
}

// 运营指标
.ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0 .15rem;
}

.li {
  width: 50%;
  padding: 0 .15rem .15rem 0;
  box-sizing: border-box;
}

.li:nth-child(2n) {
  padding-right: 0;
}

.li-box {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .1rem 0;
  box-shadow: 0 .02rem .08rem 0 rgba(0, 0, 0, 0.09);
  border-radius: .05rem;
  background: url("~@/assets/images/home/info/kz.png");
  background-size: 100% 100%;
}

.li-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: .12rem;
  height: .4rem;
}

.fn-14 {
  font-size: .14rem;
}
</style>
<template>
  <van-popup class="page" v-model:show="showFlag" position="right">
    <van-nav-bar title="房源列表" left-text="返回" left-arrow @click-left="onClickLeft" />
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <!-- 文本展示区 -->
        <section class="block-item" v-for="item in tableData" :key="item.projectId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.projectName }}</div>
            <div class="title-right">
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">房间数量：</span>
              <span v-if="item.resourceTotal">{{ item.resourceTotal }}间</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">房间面积：</span>
              <span v-if="item.resourceArea">{{ item.resourceArea }}㎡</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">租赁房源：</span>
              <span v-if="item.leaseTotal">{{ item.leaseTotal }}间</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">租赁面积：</span>
              <span v-if="item.leaseArea">{{ item.leaseArea }}㎡</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">自用房源：</span>
              <span v-if="item.selfUsedTotal">{{ item.selfUsedTotal }}间</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">自用面积：</span>
              <span v-if="item.selfUsedArea">{{ item.selfUsedArea }}㎡</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">在租房源：</span>
              <span v-if="item.rentedTotal">{{ item.rentedTotal }}间</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">在租面积：</span>
              <span v-if="item.rentedArea">{{ item.rentedArea }}㎡</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">空置房源：</span>
              <span v-if="item.releasedTotal">{{ item.releasedTotal }}间</span>
              <span v-else>-</span>
            </p>
            <p>
              <span class="info-color">空置面积：</span>
              <span v-if="item.releasedArea">{{ item.releasedArea }}㎡</span>
              <span v-else>-</span>
            </p>
          </div>
        </section>
        <!-- 文本展示区end -->
      </van-list>
    </main>
  </van-popup>
</template>

<script>
import { getRoomList } from "@/api/home/home.js";
export default {
  data() {
    return {
      showFlag: false,
      // table表格
      tableData: [],
      loading: false,
      finished: false,
      tableParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    // 【请求】获取表格数据
    getRoomList() {
      let data = this.tableParams;
      getRoomList(data).then((res) => {
        if (res.success && res.data) {
          this.tableData = [...this.tableData, ...res.data];
          this.loading = false;
          if (this.tableData.length >= res.count) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 【请求】下拉加载
    onLoad() {
      this.tableParams.page++;
      this.getRoomList();
    },

    // 【监听】筛选重载
    reset() {
      this.tableData = [];
      this.finished = false;
      this.tableParams.page = 1;
      this.getRoomList();
    },

    // 【监听】打开
    onShow() {
      this.showFlag = true;
      this.reset();
    },

    // 【监听】返回
    onClickLeft() {
      this.showFlag = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.van-nav-bar {
  background: #1989fa;
}

main {
  flex: 1;
  overflow: auto;
}

// 文本展示

.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  background: #fff;

  >.block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;

    .title-left {
      display: flex;
      align-items: center;

      >i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }

  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;

    >p {
      display: flex;
      align-items: center;
      padding: 0.02rem 0;
      font-size: .14rem;
    }

    .info-color {
      display: flex;
      align-items: center;

      .van-image {
        margin-right: 0.05rem;
      }
    }
  }
}
</style>

<style lang="scss">
.page {

  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff;
  }
}
</style>
export const menuList = [{
    title: "房源",
    imgSrc: require("../assets/images/home/menu/fangyuan.png"),
    path: "/resource/resourceList"
  },
  {
    title: "线索",
    imgSrc: require("../assets/images/home/menu/xiansuo.png"),
    path: "/clue/clueList"
  },
  {
    title: "合同",
    imgSrc: require("../assets/images/home/menu/hetong.png"),
    path: "/contract/contractType"
  },
  {
    title: "收款",
    imgSrc: require("../assets/images/home/menu/yingshoukuan.png"),
    path: "/bill/billList"
  },
]

// 园区成熟标志
export const operationStatusObj = {
  1: "筹备期",
  // 2: "招商期",
  3: "运营期",
  4: "停止运营",
  5: "招商期",
}
<template>
  <div id="header">
    <van-dropdown-menu>
      <van-dropdown-item v-model="projectId" :options="progectList" @change="onProjectId" />
    </van-dropdown-menu>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserInfo } from "@/api/mine/mine";
import { getChangeProject } from "@/api/home/home";
export default {
  data() {
    return {
      userInfo: "",
      projectId: "",
      progectList: [],
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 【请求】变更所选园区
    getChangeProject() {
      let data = {
        viewMode: "1",
        selectedProjectIdSet: this.projectId ? [this.projectId] : [],
        selectedUnitIdSet: [],
      };
      getChangeProject(data).then((res) => {
        if (res.success) {
          setTimeout(function () {
            Toast("园区切换成功");
            window.location.reload();
          }, 500);
        }
      });
    },

    // 【请求】用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.data) {
          this.userInfo = res.data;
          if (res.data.selectedProjectIdSet.length == 1) {
            this.projectId = res.data.selectedProjectIdSet[0];
          } else {
            this.projectId = "";
          }
          if (res.data.empProjectMap[1]) {
            let progectList = res.data.empProjectMap[1].map((i) => {
              return {
                text: i.projectName,
                value: i.projectId,
              };
            });
            this.progectList = [
              {
                text: "全部园区",
                value: "",
              },
              ...progectList,
            ];
          } else {
            this.progectList = [
              {
                text: "全部园区",
                value: "",
              },
            ];
          }
        }
      });
    },

    // 【监听】园区选择
    onProjectId() {
      this.getChangeProject();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/theme";

#header {
  height: 0.46rem;
  padding: 0 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $theme-color;

  // 下拉选项
  .van-dropdown-menu {
    height: 100%;
  }

  .van-dropdown-menu__bar {
    background-color: $theme-color !important;
    height: 100%;

    .van-dropdown-menu__title {
      color: #fff;
    }

  }

  .van-dropdown-item__option--active {
    color: $theme-color ;
  }

  // 切换角色
  .van-icon {
    color: #fff;
  }
}
</style>
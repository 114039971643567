import Request from '@/utils/request';

// 登录
export function userlogin(data) {
  return Request('post', '/api/user/login', data)
}

// 授权登录
export function openIdLogin(data) {
  return Request('post', '/api/user/authorized/login', data)
}

// 用户信息
export function getUserInfo() {
  return Request('get', '/apg/system/user/current/loginInfo')
}